// oooooooooo.  oooooooooooo       .o.       ooooooooooooo  .oooooo..o
// `888'   `Y8b `888'     `8      .888.      8'   888   `8 d8P'    `Y8
//  888     888  888             .8"888.          888      Y88bo.
//  888oooo888'  888oooo8       .8' `888.         888       `"Y8888o.
//  888    `88b  888    "      .88ooo8888.        888           `"Y88b
//  888    .88P  888       o  .8'     `888.       888      oo     .d8P
// o888bood8P'  o888ooooood8 o88o     o8888o     o888o     8""88888P'

// B E A T S — GEN-0
// @2075
// @beatsbykabuki

import * as Tone from 'tone'
import Midi from 'jsmidgen'

//

let pitch_table    = [ 57, 62, 64, 66, 68, 71 ]

let velocity_mul   = 0.1
let velocity_table = [ 0, 4, 6, 8, 10 ]

let pan_off		 = 1
let pan_mul        = 10
let pan_table      = [
	[ 0, 1, 2 ], // FL
	[ 2, 1, 2 ], // FR
	[ 2, 1, 0 ], // BR
	[ 0, 1, 0 ], // BL
]

let tempo = 250    // tempo in ms
let bpm = 140      // tempo in bpm
let steps = 16     // steps per pattern
let max_tracks = 4 // tracks

let frame = 0        // current frame
let active = 0       // active tracks
let play = false     // paused
let mute = false     // unmuted

let rot = 0
let rot_step = 10    // 360 / max_tracks

//	DOM

let app = document.getElementById('app')

const toggle_play = async () => {
	play = !play
	console.log( '=====', (play) ? 'PLAY' : 'STOP', '=====' )
	if (play) await Tone.start()
	active = 1
}

const toggle_mute = async () => {
	mute = !mute
	console.log( '=====', (mute) ? 'MUTE' : 'UNMUTE', '=====' )
}

app!.querySelector<HTMLInputElement>('.play-btn')!.addEventListener('click', () => toggle_play() )
app!.querySelector<HTMLInputElement>('.mute-btn')!.addEventListener('click', () => toggle_mute() )
app!.querySelector<HTMLInputElement>('.dwn-btn')!.addEventListener('click', () => handle_download(), false )

//	patterns + tracks

// get random pitch
const get_random_pitch = () => {
	return pitch_table[Math.floor(Math.random() * pitch_table.length)]
}

// get random velocity from range
const get_random_velocity_from_range = () => {
	return velocity_table[Math.floor(Math.random() * velocity_table.length)]
}

// generate pitch / velocity tuples
const get_tuple = () => {
	return [
		get_random_pitch(),
		get_random_velocity_from_range()
	]
}

const get_random_pattern = (steps: number) => {
	return [...new Array(steps)].map(() => get_tuple())
}

// a. generate tuples for each step per voice
// b. store current and max pos to
// iterate and mutate the interval
const tracks = [ ...new Array( max_tracks ) ]
	.map( ( track, index ) => {
		// console.log('generate track', index)
		const pattern = {
			pattern: get_random_pattern(steps),
			pos: 0,
			max: steps
		}
		// console.log('pattern',pattern)
		return pattern
	})

// generate a bytestream for patterns, envelopes, panning
const encode_genesis_hash = () => {

	let stream = ''

	// bpm = 2 bytes
	stream += bpm.toString(16)

	// steps = 2 bytes
	stream += steps.toString(16)

	// tracks = 1 byte
	stream += max_tracks.toString(16)

	// patterns ~48 bytes
	// ( 2 bytes pitch, 1 byte vel )
	// for 4 tracks x 16 steps
	const patterns = tracks.map( ( track, index ) => {
		// console.log('pattern', index)
		track.pattern.map( ( t, i ) => {
			// console.log(i,t)
			const pitch = t[0].toString(16)
			const velocity = (t[1]).toString(16)
			stream += pitch + velocity
		})
	})

	// console.log(stream, (stream.length-5) / 4)

	// stream += '----'

	// pitch table length, values
	stream += pitch_table.length.toString(16)
	const pt = pitch_table.map( _ => {
		// console.log( _,  _.toString(16))
		stream += _.toString(16)
	})

	// stream += '----'

	// velocity table length, values
	stream += velocity_table.length.toString(16)
	const vt = velocity_table.map( _ => {
		stream += ( _ ).toString(16)
	})

	// stream += '----'

	// offset, multiplier = 2 bytes
	stream += pan_off.toString(16)
	stream += pan_mul.toString(16)

	// each position is a triple,
	// therefore x3 = 12 bytes
	stream += (pan_table.length * 3).toString(16)
	const pos = pan_table.map( _ => {
		_.map( t => {
			// console.log(t, t.toString(16))
			stream += t.toString(16)
		})
	})

	console.log(
		'encoded',
		stream.length,
		'bytes\n',
		stream
	)

	return stream

}

// generate a bytestream for patterns, envelopes, panning
const decode_genesis_hash = ( stream: string ) => {

	console.log('decoding hash...')
	let off = 0
	let len = 0

	// bpm = 2 bytes
	len = 2
	let _bpm = parseInt( stream.slice( off, len ), 16 )
	console.log( 'bpm', _bpm )

	// steps = 2 bytes
	off += len
	len = 2
	let _steps = parseInt( stream.slice( off, off + len ), 16 )
	console.log( 'steps', _steps )

	// tracks = 1 byte
	off += len
	len = 1
	let _tracks = parseInt( stream.slice( off, off + len ), 16 )
	console.log( 'tracks', _tracks )

	// calculate len for patterns and tracks
	off += len
	len = _tracks * _steps * 3
	let patterns_raw = stream.slice( off, off + len )

	let patterns_arr = new Array()
	for ( let p = 0; p < _tracks; p++ ) {
		const length = ( _steps * 3 )
		const offset = p * length
		const fragment = patterns_raw.slice( offset, offset + length )
		const arr = new Array()
		for ( let t = 0; t < _steps; t++ ) {
			const l = 3
			const o = t * l
			const n = parseInt( fragment.slice( o, o + 2 ), 16 )
			const v = parseInt( fragment.slice( o + 2, o + 3 ), 16 )
			const tuple = [ n, v ]
			arr.push(tuple)
		}
		patterns_arr.push(arr)
	}

	let tracks = '\ntracks:\n'

	for ( let s = 0; s < _steps; s ++ ) {
		let line = s.toString().padStart(4,'_') + '___'

		for ( let t = 0; t < _tracks; t++ ) {
			line +=
				get_note_name(patterns_arr[t][s][0]).padStart(3,'_').padEnd(4,'_') + '___' +
				patterns_arr[t][s][1].toString().padStart(3,'0') +  '___'
		}

		tracks += ( line + '\n' )

	}



	console.log(
		patterns_raw,
		// patterns_arr,
		tracks
	)

	off += len
	len = 1
	let _pitch_table_length = parseInt( stream.slice( off, off + len), 16 )

	off += len
	len = _pitch_table_length
	let _pitch_table_raw = stream.slice( off, off + len)

	let _pitch_table = new Array()
	for ( let pt = 0; pt < _pitch_table_length; pt++ )

	// // pitch table length, values
	// stream += pitch_table.length.toString(16)
	// const pt = pitch_table.map( _ => {
	// 	// console.log( _,  _.toString(16))
	// 	stream += _.toString(16)
	// })

	// // stream += '----'

	// // velocity table length, values
	// stream += velocity_table.length.toString(16)
	// const vt = velocity_table.map( _ => {
	// 	stream += ( _ ).toString(16)
	// })

	// // stream += '----'

	// // offset, multiplier = 2 bytes
	// stream += pan_off.toString(16)
	// stream += pan_mul.toString(16)

	// // each position is a triple,
	// // therefore x3 = 12 bytes
	// stream += (pan_table.length * 3).toString(16)
	// const pos = pan_table.map( _ => {
	// 	_.map( t => {
	// 		// console.log(t, t.toString(16))
	// 		stream += t.toString(16)
	// 	})
	// })

}

//
let active_tracks: boolean[] = [...new Array(max_tracks)].fill(false)
const enable_track = ( n: number ) => { active_tracks[n] = true }
const disable_track = ( n: number ) => { active_tracks[n] = false }

// midi notes to note names
const note_names = [
	'C',
	'C#',
	'D',
	'D#',
	'E',
	'F',
	'F#',
	'G',
	'G#',
	'A',
	'A#',
	'B'
]

const get_note_name = (midi_note: number) => {
	return note_names[midi_note % 12] + Math.floor(midi_note / 12 - 1)
}

// setup instruments


const masterChannel = new Tone.Channel({
	channelCount: 2
}).toDestination();

const synth = [...new Array(max_tracks)].map((t,i)=>{

	// console.log( 'set up instrument', i )

	const fx = new Tone.Vibrato({
		frequency: 10,
		depth: 1,
	}).toDestination()

	const [ x, y, z ] = pan_table[i]

	// console.log( x, y, z )
	// console.log(
	// 	( x - pan_off ) * pan_mul,
	// 	( y - pan_off ) * pan_mul,
	// 	( z - pan_off ) * pan_mul
	// )

	const pan = new Tone.Panner3D({
		panningModel: "HRTF",
		positionX: ( x - pan_off ) * pan_mul,
		positionY: ( y - pan_off ) * pan_mul,
		positionZ: ( z - pan_off ) * pan_mul,
		refDistance: 10,
		rolloffFactor: 1,
	}).toDestination()

	const r = ( Math.random() * 10 )
	const rev = new Tone.Reverb(r).toDestination()

	const instrument = new Tone.FMSynth({

		// FM Index
		// 2:1 3:1 4:1 5:1
		// + velocity --> louder == brighter sound
		modulationIndex: 21.22,

		envelope: {
			attack: 0.01,
			decay: 0.01,
		},

		modulation: {
			type: "sine"
		},

		modulationEnvelope: {
			attack: 0.03,
			decay: 0.03
		},

		volume: -10,

	})
	// .connect(fx)
	.connect(pan)
	// .connect(rev)
	.connect(masterChannel)

	return instrument

})

// play note

function play_note( channel: number ) {

		if ( active_tracks[channel] !== true ) return

		let note, now, vel = 0

		// get note at playhead
		const pos = tracks[channel].pos
		const max = tracks[channel].max
		const t = tracks[channel].pattern[pos]

		if ( max > 0 ) {

			note = get_note_name(t[0])
			now = Tone.now()
			vel = t[1]

			synth[channel].volume.value = 0 - ( 8 / vel )
			synth[channel].harmonicity.value = ( vel * velocity_mul * 12.3 )
			synth[channel].triggerAttackRelease( note, '32n', now )

		}

		if ( pos === steps-1 ) {
			// console.log('add a track')
			active = ( active < max_tracks ) ? active + 1 : active
		}

		// move playhead
		if ( pos >= ( max - 1 ) ) { // reached end?

			tracks[channel].pos = 0
			tracks[channel].max = ( max > 0 ) ? max - 1 : 0

		} else {

			tracks[channel].pos = pos + 1

		}

		// if ( max > 0 ) {
		// 	console.log(
		// 		`C${channel}`,
		// 		// 'F', `${ frame }`.padStart(4, '0'),
		// 		`S ${ pos }`.padStart( 2, '0' ),
		// 		'-', `${ max }`.padStart( 2, '0' ),
		// 		note.padEnd(4,' '), vel
		// 		// Tone.now()
		// 	)
		// }

}

// rotate listener

function update_rotation() {
	set_rotation( rot )
	rot = ( rot < 360 ) ? rot + rot_step : 0
}

function set_rotation( angle: number ) {
	Tone.Listener.forwardX.value = Math.sin(angle);
	Tone.Listener.forwardY.value = 0;
	Tone.Listener.forwardZ.value = -Math.cos(angle);
}

// player

function player_update() {

	const cactive = active_tracks.filter( t => (t===true) ).length

	if ( active !== cactive ) {

		// console.log( 'active_tracks', active_tracks )
		// console.log( 'old', cactive, 'new', active)

		for ( let a: number = 0; a < max_tracks; a ++) {

			if( a < active ) {
				enable_track(a)
				// console.log(a, 'on')
			} else {
				disable_track(a)
				// console.log(a, 'off')
			}
		}

		// console.log( 'active_tracks', active_tracks )

	}

	active_tracks.filter( ( play, i ) => {
		play_note(i)
	})
}

// render loop

function render() {

	if ( play ) {

		// update_rotation()
		player_update()

		app!.querySelector('.iteration')!.innerHTML = `${frame}`
		frame++

	}

}

// native interval
// const loop = setInterval( render, tempo )

Tone.Transport.bpm.value = bpm
Tone.Transport.scheduleRepeat( (time) => { render() }, "4n" )
Tone.Transport.start()

enable_track(0)
// toggle_play()

// generate genesis
// const my_hash = encode_genesis_hash()



//
//
//
//
//

//
//	GENERATE MIDI FILE
//

const get_midi_file = ( stream: string ) => {

	console.log('decoding hash...')
	let off = 0
	let len = 0

	// bpm = 2 bytes
	len = 2
	let _bpm = parseInt( stream.slice( off, len ), 16 )
	console.log( 'bpm', _bpm )

	// steps = 2 bytes
	off += len
	len = 2
	let _steps = parseInt( stream.slice( off, off + len ), 16 )
	console.log( 'steps', _steps )

	// tracks = 1 byte
	off += len
	len = 1
	let _tracks = parseInt( stream.slice( off, off + len ), 16 )
	console.log( 'tracks', _tracks )

	// calculate len for patterns and tracks
	off += len
	len = _tracks * _steps * 3
	let patterns_raw = stream.slice( off, off + len )

	let patterns_arr = new Array()
	for ( let p = 0; p < _tracks; p++ ) {
		const length = ( _steps * 3 )
		const offset = p * length
		const fragment = patterns_raw.slice( offset, offset + length )
		const arr = new Array()
		for ( let t = 0; t < _steps; t++ ) {
			const l = 3
			const o = t * l
			const n = parseInt( fragment.slice( o, o + 2 ), 16 )
			const v = parseInt( fragment.slice( o + 2, o + 3 ), 16 )
			const tuple = [ n, v ]
			arr.push(tuple)
		}
		patterns_arr.push(arr)
	}

	const file = new Midi.File()
	const track = new Midi.Track()
	file.addTrack(track)

	let counter = 0
	for ( let s = 0; s < _steps; s ++ ) {
		for ( let t = 0; t < _tracks; t++ ) {
			track.addNote( t, get_note_name(patterns_arr[t][s][0]), patterns_arr[t][s][1])
			counter++
		}
	}
	console.log(counter, 'notes added.')
	return file.toBytes()
	// fs.writeFileSync('test.mid', file.toBytes(), 'binary');

}
function download( filename, text ) {
	const element = document.createElement('a');
	element.setAttribute('href', 'data:text/plain;charset=utf-8,' + encodeURIComponent(text));
	element.setAttribute('download', filename);
	element.style.display = 'none';
	document.body.appendChild(element);
	element.click();
	document.body.removeChild(element);
}
function handle_download {

	console.log('download...')

	const d = new Date();
	let h = d.getHours().toString().padStart(2,'0')
	let m = d.getMinutes().toString().padStart(2,'0')
	let s = d.getSeconds().toString().padStart(2,'0')
	let ms = d.getMilliseconds().toString().padStart(3,'0')
	let time = h + "" + m + "" + s + "" + ms
	const filename = 'wynq-' + time + '.midi'
	download( filename, get_midi_file( hash ) )

}

//
//
//

// consume genesis
const hash = '8c1044783903e640439a39044a42647439442a3ea44a4764783ea3e042840447044842039a3963e442a40a40a3e047a4063e439039444644447a4484443984283e64444003964244764403963ea3e040a4084263e64244443984264464784444284766393e4042444750468a1ac012212210010'
decode_genesis_hash( hash )
